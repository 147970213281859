<template>
  <div class="background">
    <div class="header">

    </div>
    <el-container style="padding: 0 20px;">
      <div class="create-team-container">
        <div class="title">创建团队</div>
        <div class="nameWrapper">
          <div style="white-space:nowrap;">团队名称：</div>
          <el-input
              style="width:300px"
              class="title-input"
              placeholder="请输入团队名称"
              v-model="teamName"
              maxlength="50">
          </el-input>
        </div>
        <div class="nameWrapper">
          <div style="white-space:nowrap;">所在行业：</div>
          <el-select v-model="industry" style="width: 300px;" placeholder="未选择行业">
            <el-option v-for="(item,index) in industries" :key="item.id" :value="item.id" :label="item.name">

            </el-option>
          </el-select>
        </div>
        <div style="margin-top: 50px;text-align: center">
          <el-button @click="createTeamSubmit" style="width: 400px;" type="primary">立即创建</el-button>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
import {
  createTeam, createTeamConfig, getIndustry
} from "../api/api";
import {checkPlatformIsMobile, MetaMessage} from "../util";
import {ElLoading, ElMessage} from "element-plus";
import config from "../config";

export default {
  name: "createTeam",
  data: function () {
    return {
      metaMessage: new MetaMessage(),
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      teamId: '',
      teamName: '',
      loadingInstance: null,
      system: this.$route.query.system || 0,
      industries:[],
      industry:''
    }
  },
  mounted() {
    this.teamId = this.userInfo.mainSiteTeamId;
    console.log('this.teamId === ', this.teamId);
    if (this.teamId) {
      this.$router.push({
        name: 'contractIndex',
      })
    }
    this.getIndustries()
  },
  methods: {
    getIndustries:function (){
      getIndustry().then(res=>{
        this.industries=res.data.data;
      }).catch(err=>{
        console.log("getIndustries err===>",err)
        this.metaMessage.error(err.msg)
      })
    },
    createTeamSubmit: function () {
      if (this.teamName === "" || !/\S/g.test(this.teamName)) {
        ElMessage.error("请输入团队名称");
        return;
      }
      if (this.industry === "") {
        ElMessage.error("请选择行业");
        return;
      }
      this.openLoading();
      createTeamConfig({teamName:this.teamName,industryId:this.industry}).then(res => {
        if (res.data.code === 0) {
          this.metaMessage['info']('创建团队成功');
          const redirect = this.$route.query.redirect;
            if(redirect.length > 0){
              this.$router.push(redirect);
            }else{
              this.$router.push({
                name: 'contractIndex',
              })
            }
        } else {
          if(res.data.code == 3906){
            this.metaMessage['error']('创建团队失败,已有团队使用该名称!');
          }else{
            this.metaMessage['error']('创建团队失败');
          }
        }
        this.closeLoading();
      }).then(()=>{
        if(this.$route.path.indexOf('viewVersionFile') > 0){
          //用户要浏览H5的pdf合同预览页面
          //
        }
      })
          .catch(err => {
        console.error('createTeam  err:', err);
        this.metaMessage['error']('创建团队失败');
        this.closeLoading();
      });
    },
    openLoading: function() {
      this.loadingInstance = ElLoading.service({
        fullscreen: true,
        body: true,
        lock: true,
        spinner: 'el-icon-loading'
      });
    },
    closeLoading: function () {
      this.loadingInstance.close();
      this.loadingInstance = null;
    }
  },
}
</script>

<style scoped>

.background{
  background-color: rgb(248, 249, 250);
  height: 100%;
}
.header{
  height: 100px;
  padding: 20px;
}

.create-team-container {
  width: 500px;margin: 0 auto;padding: 60px 30px;
  background-color: white;
  box-shadow: rgb(60 64 67 / 15%) 0 1px 3px 1px;
  text-align: center;
}

.title {
  font-size: 24px;
  color: #4c85f8;
  text-align: center;
  margin-bottom: 50px;

}

.nameWrapper {
  display: flex;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  margin: 20px auto;
  width: 400px;
  justify-content: center;
  align-items: center;
}

/*手机样式*/
@media screen and (max-width: 500px) {
  .header{
    height: 0 !important;
    padding:0 !important;
  }
  .background >>> .el-container{
    /* padding: 0px 20px; */
    position: fixed;
    left: 50%;
    top: 10%;
    transform: translate(-50%);
  }
  .background >>> .create-team-container{
    width: 80vw !important;
    margin: 0;
    padding: 10vw 5vw !important;
  }
  .background >>> .el-input,.background >>> .el-select,.create-team-container >>> .el-button{
    width: 55vw !important;
  }
  .nameWrapper{
    width: auto !important;
  }

}
</style>
